<template>
  <b-row>
    <b-col>
      <ReviewsTable
        :items="reviews"
        :fields="tblFieldsReviews"
        :is-loading="loadingStatus"
        @toggleArchive="getReviews"
      />
    </b-col>
  </b-row>
</template>

<script>
import ReviewsTable from '@/components/Reviews/Tables/ReviewsTable.vue'

export default {
  name: 'Reviews',
  components: {
    ReviewsTable,
  },
  data() {
    return {
      tblFieldsReviews: [
        { key: 'status', label: 'Status', sortable: true },
        { key: 'specification_name', label: 'Specification', sortable: true },
        { key: 'reviewers', label: 'Reviewers', sortable: true },
      ],
      reviews: [],
      loadingStatus: false,
    }
  },
  mounted() {
    this.getReviews()
  },
  methods: {
    getReviews(archivedBool = false) {
      this.loadingStatus = true
      const params = { model: this.$store.state.model.id, archived: archivedBool }
      this.$http
        .get('/api/v2/reviews', { params })
        .then(({ data }) => { this.reviews = data })
        .catch(e => console.error(e))
        .finally(() => { this.loadingStatus = false })
    },
  },
}
</script>
